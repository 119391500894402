import React from 'react';
import PropTypes from 'prop-types';
import gql from 'graphql-tag';
import { connect } from 'react-redux';
import {
  getCompanyId,
  getFlippers,
  getNonDefaultLocaleCodes,
} from 'store/modules/company';
import {
  getTheme,
  getPublicLogo,
  isBlogEnabled as getIsBlogEnabled,
  getTranslationTransform,
  PSEUDOLOCALIZE, TRANSLATION_ID, TRANSLATION_TEXT,
} from 'store/modules/site';
import { DEFAULT_LOCALE } from 'shared/i18n';
import { getUserAuthProfile } from 'store/modules/user';
import {
  getId as getPageId,
  getSlug as getPageSlug,
  getLocale as getPageLocale,
} from 'store/modules/page';
import { compose } from 'utils/react';
import useQuery from 'modules/react/hooks/useQuery';
import authorization from 'modules/authorization';
import CurrentCompanyContext from 'modules/react/contexts/CurrentCompanyContext';
import CurrentPageDataContext from 'modules/react/contexts/CurrentPageDataContext';
import CurrentSiteContext from 'modules/react/contexts/CurrentSiteContext';
import IntegrationsContext from 'modules/react/contexts/IntegrationsContext';
import CurrentUserContext from 'admin/contexts/CurrentUserContext';
import ConnectedIntlProvider from 'containers/ConnectedIntlProvider';
import { getSiteId } from '../../store/modules/site';

export const GET_INTEGRATION_DATA = gql`
  query GetIntegrations {
    integrations {
      id
      ...IntegrationsContextIntegration
    }
  }

  ${IntegrationsContext.fragments.integration}
`;

const propTypes = {
  children: PropTypes.object.isRequired,
  companyId: PropTypes.string.isRequired,
  siteId: PropTypes.string.isRequired,
  isBlogEnabled: PropTypes.bool.isRequired,
  profile: PropTypes.shape({
    hyper: PropTypes.bool,
    permissions: PropTypes.array,
  }).isRequired,
  theme: PropTypes.object.isRequired,
  publicLogo: PropTypes.object.isRequired,
  flippers: PropTypes.object.isRequired,
  nonDefaultLocales: PropTypes.array.isRequired,
  getLocale: PropTypes.func,
  translationTransform: PropTypes.oneOf([PSEUDOLOCALIZE, TRANSLATION_ID, TRANSLATION_TEXT]),
  page: PropTypes.shape({
    id: PropTypes.string.isRequired,
    slug: PropTypes.string.isRequired,
  }),
};

const defaultProps = {
  getLocale: state => getPageLocale(state) || DEFAULT_LOCALE,
};

const legacyContextTypes = {
  store: PropTypes.object.isRequired,
};

const App = ({ children,
  companyId,
  siteId,
  isBlogEnabled,
  profile,
  theme,
  publicLogo,
  flippers,
  nonDefaultLocales,
  getLocale,
  translationTransform,
  page,
}) => {
  const { data } = useQuery(GET_INTEGRATION_DATA);

  const integrations = {
    integrations: data?.integrations || [],
    loaded: true,
    loading: false,
  };

  const currentCompany = {
    id: companyId,
    loaded: true,
    loading: false,
    flippers,
    nonDefaultLocales,
  };

  const currentSite = {
    id: companyId,
    siteId,
    isBlogEnabled,
    theme,
    publicLogo,
    loading: false,
    loaded: true,
    translationTransform,
  };

  const user = {
    id: profile && profile.id,
    loading: false,
    loaded: true,
    ...authorization(profile),
  };

  return (
    <IntegrationsContext.Provider value={integrations}>
      <ConnectedIntlProvider getLocale={getLocale}>
        <CurrentCompanyContext.Provider value={currentCompany}>
          <CurrentSiteContext.Provider value={currentSite}>
            <CurrentPageDataContext.Provider value={page}>
              <CurrentUserContext.Provider value={user}>
                {children}
              </CurrentUserContext.Provider>
            </CurrentPageDataContext.Provider>
          </CurrentSiteContext.Provider>
        </CurrentCompanyContext.Provider>
      </ConnectedIntlProvider>
    </IntegrationsContext.Provider>
  );
};

App.propTypes = propTypes;
App.defaultProps = defaultProps;
App.contextTypes = legacyContextTypes;

export default compose(
  connect(
    state => ({
      theme: getTheme(state),
      publicLogo: getPublicLogo(state),
      companyId: getCompanyId(state),
      siteId: getSiteId(state),
      isBlogEnabled: getIsBlogEnabled(state),
      profile: getUserAuthProfile(state),
      flippers: getFlippers(state),
      translationTransform: getTranslationTransform(state),
      nonDefaultLocales: getNonDefaultLocaleCodes(state),
      page: {
        id: getPageId(state),
        slug: getPageSlug(state),
        locale: getPageLocale(state),
      },
    }),
  ),
)(App);
