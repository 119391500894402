/*  eslint-disable max-len */
import { ID as ACCORDION_ID } from './Accordion/properties';
import { ID as BLOG_CATEGORIES_ID } from './BlogCategories/properties';
import { ID as BLOG_HOME_ID } from './BlogHome/properties';
import { ID as CAROUSEL_ID } from './Carousel/properties';
import { ID as CONTACT_FORM_ID } from './ContactForm/properties';
import { ID as COUPON_ID } from './Coupon/properties';
import { ID as EMBED_CONTENT_ID } from './EmbedContent/properties';
import { ID as FACILITY_AMENITIES_ID } from './FacilityAmenities/properties';
import { ID as FACILITY_GROUP_ID } from './FacilityGroup/properties';
import { ID as FACILITY_IMAGE_SLIDESHOW_ID } from './FacilityImageSlideShow/properties';
import { ID as FACILITY_REVIEWS_ID } from './FacilityReviews/properties';
import { ID as FACILITY_SEARCH_BAR_ID } from './FacilitySearchBar/properties';
import { ID as FACILITY_UNITS_ONE_ID } from './FacilityUnitsOne/properties';
import { ID as FACILITY_UNITS_THREE_ID } from './FacilityUnitsThree/properties';
import { ID as FACILITY_UNITS_TWO_ID } from './FacilityUnitsTwo/properties';
import { ID as GOOGLE_TRANSLATE_ID } from './GoogleTranslate/properties';
import { ID as IMAGE_ID } from './Image/properties';
import { ID as IMAGE_GRID_ID } from './ImageGrid/properties';
import { ID as LOCATIONS_NEAR_YOU_ID } from './LocationsNearYou/properties';
import { ID as MAPBOX_MAP_ONE_FACILITY_ID } from './MapboxMapOneFacility/properties';
import { ID as MAPBOX_MAP_ALL_FACILITIES_ID } from './MapboxMapAllFacilities/properties';
import { ID as NAVIGATION_ID } from './Navigation/properties';
import { ID as NAVIGATION_V2_ID } from './NavigationV2/properties';
import { ID as NEARBY_LOCATIONS_ID } from './NearbyLocations/properties';
import { ID as PAY_ONLINE_ID } from './PayOnline/properties';
import { ID as POP_UP_ID } from './PopUp/properties';
import { ID as PROMOTION_BANNER_ID } from './PromotionBanner/properties';
import { ID as REVIEW_BUTTON_ID } from './ReviewButton/properties';
import { ID as REVIEW_STARS_ID } from './ReviewStars/properties';
import { ID as REVIEWS_ID } from './Reviews/properties';
import { ID as STANDARD_UNIT_LISTING_ID } from './StandardUnitListing/properties';
import { ID as STORAGE_CALCULATOR_ID } from './StorageCalculator/properties';
import { ID as WEB_VITALS_ID } from '../../containers/WebVitals/properties';
import { ID as VIDEO_ID } from './Video';

export default {
  [ACCORDION_ID]: () => import('./Accordion/legacy'),
  [BLOG_CATEGORIES_ID]: () => import('./BlogCategories/legacy'),
  [BLOG_HOME_ID]: () => import('./BlogHome/legacy'),
  [CAROUSEL_ID]: () => import('./Carousel/legacy'),
  [CONTACT_FORM_ID]: () => import('./ContactForm/legacy'),
  [COUPON_ID]: () => import('./Coupon/legacy'),
  [EMBED_CONTENT_ID]: () => import('./EmbedContent/legacy'),
  [FACILITY_AMENITIES_ID]: () => import('./FacilityAmenities/legacy'),
  [FACILITY_GROUP_ID]: () => import('./FacilityGroup/legacy'),
  [FACILITY_IMAGE_SLIDESHOW_ID]: () => import('./FacilityImageSlideShow/legacy'),
  [FACILITY_REVIEWS_ID]: () => import('./FacilityReviews/legacy'),
  [FACILITY_SEARCH_BAR_ID]: () => import('./FacilitySearchBar/legacy'),
  [FACILITY_UNITS_ONE_ID]: () => import('./FacilityUnitsOne/legacy'),
  [FACILITY_UNITS_THREE_ID]: () => import('./FacilityUnitsThree/legacy'),
  [FACILITY_UNITS_TWO_ID]: () => import('./FacilityUnitsTwo/legacy'),
  [GOOGLE_TRANSLATE_ID]: () => import('./GoogleTranslate/legacy'),
  [IMAGE_ID]: () => import('./Image/legacy'),
  [IMAGE_GRID_ID]: () => import('./ImageGrid/legacy'),
  [LOCATIONS_NEAR_YOU_ID]: () => import('./LocationsNearYou/legacy'),
  [MAPBOX_MAP_ALL_FACILITIES_ID]: () => import('./MapboxMapAllFacilities/legacy'),
  [MAPBOX_MAP_ONE_FACILITY_ID]: () => import('./MapboxMapOneFacility/legacy'),
  [NAVIGATION_ID]: () => import('./Navigation/legacy'),
  [NAVIGATION_V2_ID]: () => import('./NavigationV2/legacy'),
  [NEARBY_LOCATIONS_ID]: () => import('./NearbyLocations/legacy'),
  [PAY_ONLINE_ID]: () => import('./PayOnline/legacy'),
  [POP_UP_ID]: () => import('./PopUp/legacy'),
  [PROMOTION_BANNER_ID]: () => import('./PromotionBanner/legacy'),
  [REVIEW_BUTTON_ID]: () => import('./ReviewButton/legacy'),
  [REVIEW_STARS_ID]: () => import('./ReviewStars/legacy'),
  [REVIEWS_ID]: () => import('./Reviews/legacy'),
  [STANDARD_UNIT_LISTING_ID]: () => import('./StandardUnitListing/legacy'),
  [STORAGE_CALCULATOR_ID]: () => import('./StorageCalculator/legacy'),
  [WEB_VITALS_ID]: () => import('../../containers/WebVitals'),
  [VIDEO_ID]: () => import('./Video/legacy'),
};
