import { query } from 'utils/graphql';
import * as allFlippers from '../../../../constants/flippers';

// Actions
export const LOAD_APP = 'voyager/app/LOAD_APP';
export const LOAD_APP_SUCCESS = 'voyager/app/LOAD_APP_SUCCESS';
export const LOAD_APP_FAIL = 'voyager/app/LOAD_APP_FAIL';

const FontProps = `
  fragment FontProps on Font {
    href
    name
  }
`;

const ElementProps = `
  fragment ElementProps on Element {
    color
    font
    weight
    lineHeight
    fontSize
    letterSpacing
    textDecoration
    textTransform
    position
  }
`;

const BodyElementProps = `
  fragment BodyElementProps on Element {
    color
    font
    weight
    lineHeight
    fontSize
    letterSpacing
    textDecoration
    textTransform
    position
    whiteSpace
  }
`;

const ButtonElementProps = `
 fragment ButtonElementProps on ButtonElement {
    color
    font
    lineHeight
    fontSize
    weight
    backgroundColor
    activeBackgroundColor
    activeBoxShadow
    hoverBackgroundColor
    textAlign
    textTransform
    borderRadius
 }
`;

const LinkElementProps = `
  fragment LinkElementProps on LinkElement {
    color
    font
    weight
    lineHeight
    fontSize
    letterSpacing
    textDecoration
    textTransform
    position
    hoverColor
    hoverTextDecoration
    visitedColor
    visitedTextDecoration
  }
`;

const ImageElementProps = `
 fragment ImageElementProps on ImageElement {
    borderRadius
 }
 `;

const TokenProps = `
  fragment TokenProps on TranslationToken {
    key
    value
    defaultValue
  }
`;

const TranslationProps = `
  fragment TranslationProps on TranslationSet {
    enabled
    language
    tokens {
      ...TokenProps
    }
  }

  ${TokenProps}
`;

const ThemeProps = `
  fragment ThemeProps on Theme {
    breadcrumbsDelineator
    colors {
      primary
      secondary
      tertiary
    }
    fonts {
      primary {
        ...FontProps
      }
      secondary {
        ...FontProps
      }
    }
    elements {
      md {
        h1 {
          ...ElementProps
        }
        h2 {
          ...ElementProps
        }
        h3 {
          ...ElementProps
        }
      }
      h1 {
        ...ElementProps
      }
      h2 {
        ...ElementProps
      }
      h3 {
        ...ElementProps
      }
      h4 {
        ...ElementProps
      }
      h5 {
        ...ElementProps
      }
      h6 {
        ...ElementProps
      }
      body {
        ...BodyElementProps
      }
      a {
        ...LinkElementProps
      }
      input {
        ...ElementProps
      }
      caption {
        ...ElementProps
      }
      blockquote {
        ...ElementProps
      }
      button {
        ...ButtonElementProps
      }
      img {
        ...ImageElementProps
      }
    }
    map {
      pins {
        primary {
          data
        }
        secondary {
          color
          data
          type
          label {
            x
            y
          }
        }
      }
    }
  }

  ${FontProps}
  ${ElementProps}
  ${BodyElementProps}
  ${LinkElementProps}
  ${ButtonElementProps}
  ${ImageElementProps}
`;

const flippers = Object.keys(allFlippers).map(flipper => allFlippers[flipper]).join(' ');

const SiteProps = `
  fragment SiteProps on Site {
    id
    blogSettings {
      enabled
      title
    }
    companyId
    domain
    email
    facebookPixelId
    googleAnalyticsId
    googleTagManagerId
    publicLogo {
      alt
      link {
        href
        target
      }
      medium {
        id
        filename
        url
        placeholder
        srcSet
        sourceWidth
        sourceHeight
        trash
        defaultMetadata {
          title
          altText
        }
        metadata {
          title
          altText
        }
      }
      title
      url
    }
    publicFavicon {
      url
    }
    identifier
    pageSet
    phone
    primaryLayoutId
    url
    actionButtons {
      call
      moveIn
      reserve
    }
    primaryColor
    secondaryColor
    tertiaryColor
    socialMedia {
      provider
      link
      enabled
    }
    support {
      phone
      email
    }
    theme {
      ...ThemeProps
    }
    customCompanyNameEnabled
    customCompanyName
  }

  ${ThemeProps}
`;

const CompanyProps = `
  fragment CompanyProps on Company {
    id
    email
    name
    phone
    companySettingsUrl
    corporateUrl
    storEdgeUrl
    address {
      address1
      address2
      city
      state
      postal
      country
      fullAddress
    }
    flippers {
      ${flippers}
    }
    locales {
      code
      name
    }
    sources {
      name
      category
      deleted
    }
  }
`;

export function load(force = false) {
  return {
    types: [LOAD_APP, LOAD_APP_SUCCESS, LOAD_APP_FAIL],
    force,
    promise: query`
      {
        user {
          location {
            latitude
            longitude
          }
          ipAddress
        }
        hostnames {
          id
          hostname
          redirects {
            source
            target
          }
          primary
          ssl
        }
        site {
          ...SiteProps
          translations {
            ...TranslationProps
          }
        }
        company {
          ...CompanyProps
          translations {
            ...TranslationProps
          }
        }
      }

      ${CompanyProps}
      ${SiteProps}
      ${TranslationProps}
    `,
  };
}

export function loadAppForClient({ force = false, locale = 'en-us' }) {
  return {
    types: [LOAD_APP, LOAD_APP_SUCCESS, LOAD_APP_FAIL],
    force,
    promise: query`
      {
        user {
          location {
            latitude
            longitude
          }
          ipAddress
        }
        hostnames {
          id
          hostname
          primary
          ssl
        }
        site {
          ...SiteProps
          translations(language: "${locale}") {
            ...TranslationProps
          }
        }
        company {
          ...CompanyProps
          translations(locale: "${locale}") {
            ...TranslationProps
          }
        }
      }

      ${CompanyProps}
      ${SiteProps}
      ${TranslationProps}
    `,
  };
}
