import merge from 'shared/merge';
import { query } from 'utils/graphql';
import {
  LOAD_APP,
  LOAD_APP_SUCCESS,
  LOAD_APP_FAIL,
} from './app';

// Actions
const LOAD_NAVIGATION = 'voyager/site/LOAD_NAVIGATION';
const LOAD_NAVIGATION_SUCCESS = 'voyager/site/LOAD_NAVIGATION_SUCCESS';
const LOAD_NAVIGATION_FAIL = 'voyager/site/LOAD_NAVIGATION_FAIL';
const SAVE = 'voyager/site/SAVE';
const SAVE_SUCCESS = 'voyager/site/SAVE_SUCCESS';
const SAVE_FAIL = 'voyager/site/SAVE_FAIL';
const BUILD = 'voyager/site/BUILD';
const BUILD_SUCCESS = 'voyager/site/BUILD_SUCCESS';
const BUILD_FAIL = 'voyager/site/BUILD_FAIL';
const TOGGLE_AUTHENTICATED_PAGES = 'voyager/site/TOGGLE_AUTHENTICATED_PAGES';
const SET_TRANSFORM_PSEUDO = 'voyager/site/SET_TRANSFORM_PSEUDO';
const SET_TRANSFORM_TRANSLATION_ID = 'voyager/site/SET_TRANSFORM_SHOW_ID';
const SET_TRANSFORM_TRANSLATION_TEXT = 'voyager/site/SET_TRANSLATION_TRANSFORM_TRANSLATION';
const TOGGLE_BLOG_SETTINGS = 'voyager/site/TOGGLE_BLOG_SETTINGS';

export const PSEUDOLOCALIZE = 'pseudoLocalize';
export const TRANSLATION_ID = 'showTranslationId';
export const TRANSLATION_TEXT = 'translation';

const NavigationFields = `
  fragment NavigationFields on Navigation {
    title
    link
    target
    pageId
    page {
      id
      path
      locale
      metadata {
        slug
        title
      }
    }
    isGroup
  }
`;

const NavigationProps = `
  fragment NavigationProps on Navigation {
    ...NavigationFields
    children {
      ...NavigationFields
      children {
        ...NavigationFields
        children {
          ...NavigationFields
        }
      }
    }
  }

  ${NavigationFields}
`;

const LocaleNavigationProps = `
  fragment LocaleNavigationProps on LocaleNavigation {
    locale
    navigation {
      ...NavigationProps
    }
  }

  ${NavigationProps}
`;

// Reducer
const initialState = {
  loaded: false,
  blogSettings: {
    enabled: false,
    title: 'Storage Blog',
  },
  onlyShowAuthenticated: false,
  translationTransform: TRANSLATION_TEXT,
};

export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case LOAD_APP:
    case LOAD_NAVIGATION:
    case BUILD:
      return {
        ...state,
        loading: true,
      };
    case LOAD_APP_SUCCESS:
    case BUILD_SUCCESS:
      return {
        ...state,
        loading: false,
        loaded: true,
        ...action.data.site,
      };
    case LOAD_NAVIGATION_SUCCESS:
      return {
        ...state,
        loading: false,
        navigations: action.data.site.navigations,
      };
    case LOAD_APP_FAIL:
    case LOAD_NAVIGATION_FAIL:
    case BUILD_FAIL:
      return {
        ...state,
        loading: false,
        loaded: false,
        error: action.error,
      };
    case SAVE:
      return {
        ...state,
        saving: true,
        error: null,
      };
    case SAVE_SUCCESS: {
      const stateToMerge = { ...state };
      // We need to take special care not to merge certain changes and instead
      // just accept the replacements
      if (action.site.hostnames) {
        stateToMerge.hostnames = [];
      }
      if (action.site.navigations) {
        stateToMerge.navigations = [];
      }
      if (action.site.socialMedia) {
        stateToMerge.socialMedia = [];
      }
      return merge(stateToMerge, action.site, { saving: false });
    }
    case SAVE_FAIL:
      return {
        ...state,
        error: action.error,
        saving: false,
      };
    case TOGGLE_AUTHENTICATED_PAGES:
      return {
        ...state,
        onlyShowAuthenticated: !state.onlyShowAuthenticated,
      };
    case SET_TRANSFORM_PSEUDO:
      return {
        ...state,
        translationTransform: PSEUDOLOCALIZE,
      };
    case SET_TRANSFORM_TRANSLATION_ID:
      return {
        ...state,
        translationTransform: TRANSLATION_ID,
      };
    case SET_TRANSFORM_TRANSLATION_TEXT:
      return {
        ...state,
        translationTransform: TRANSLATION_TEXT,
      };
    case TOGGLE_BLOG_SETTINGS:
      return {
        ...state,
        blogSettings: {
          ...state.blogSettings,
          enabled: !state.blogSettings.enabled,
        },
      };
    default:
      return state;
  }
}

// Selectors
export const getSite = state => (
  state.site
);

export const getSiteId = state => (
  state.site.id
);

export const isBlogEnabled = state => (
  !!state.site.blogSettings.enabled
);

export const getCompanyId = state => (
  state.site.companyId
);

export const getTheme = state => (
  state.site.theme
);

export const getColors = state => (
  state.site.theme.colors
);

export const getFonts = state => (
  state.site.theme.fonts
);

export const getElement = (state, element) => (
  state.site.theme.elements[element] || {}
);

export const getMdElement = (state, element) => (
  state.site.theme.elements.md[element] || {}
);

export const getTranslationTransform = state => (
  state.site.translationTransform
);

export const getSupport = state => state.site.support;

export const getSocialMedia = state => state.site.socialMedia;

export const getTranslations = state => state.site.translations;

export const getFavicon = state => state.site.publicFavicon.url;

export const getPublicLogo = state => state.site.publicLogo;

export const getActionButtons = state => state.site.actionButtons;

export const getFacebookPixelId = state => state.site.facebookPixelId;

export const getGoogleTagManagerId = state => state.site.googleTagManagerId;

export const getGoogleAnalyticsId = state => state.site.googleAnalyticsId;

// Action Creators
export function loadNavigation() {
  return {
    types: [LOAD_NAVIGATION, LOAD_NAVIGATION_SUCCESS, LOAD_NAVIGATION_FAIL],
    force: true,
    promise: query`
      {
        site {
          id
          companyId
          navigations {
            ...LocaleNavigationProps
          }
        }
      }

      ${LocaleNavigationProps}
    `,
  };
}

export function save(data) {
  // TODO: Pass the properly formatted and updated `navigations` array in as a
  // prop on `data` instead of formatted here.
  const { navigations } = data;

  let site;
  if (navigations) {
    site = {
      navigations,
    };
  }

  return {
    types: [SAVE, SAVE_SUCCESS, SAVE_FAIL],
    force: true,
    site: site || data,
    promise: query`
      mutation UpdateSite(${{ site: site || data }}: SiteInput!) {
        updateSite(site: $site)
      }
    `,
  };
}

export function build(pageSet) {
  return {
    types: [BUILD, BUILD_SUCCESS, BUILD_FAIL],
    force: true,
    promise: query`
      mutation BuildSite(${{ pageSet }}: String!) {
        site: buildSite(pageSet: $pageSet) {
          navigations {
            ...LocaleNavigationProps
          }
          primaryLayoutId
          pageSet
        }
      }

      ${LocaleNavigationProps}
    `,
  };
}

export function toggleAuthenticatedPages() {
  return {
    type: TOGGLE_AUTHENTICATED_PAGES,
  };
}

export function setTransformPseudo() {
  return {
    type: SET_TRANSFORM_PSEUDO,
  };
}

export function setTransformTranslationId() {
  return {
    type: SET_TRANSFORM_TRANSLATION_ID,
  };
}

export function setTransformTranslationText() {
  return {
    type: SET_TRANSFORM_TRANSLATION_TEXT,
  };
}

export function toggleBlogSettings() {
  return {
    type: TOGGLE_BLOG_SETTINGS,
  };
}

// Global
export function isLoaded(globalState) {
  return globalState.site && globalState.site.loaded;
}
